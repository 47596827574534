import React from "react"
import Layout from "../components/layout"
import PageBackground from "../components/page-background/page-background.component"
import ContactBanner from "../layouts/ContactBanner"

const NotFoundPage = () => (
  <Layout
    seo={{
      title: "Thank You",
      metaRobotsNoindex: "noindex",
      metaRobotsNofollow: "nofollow",
    }}
    hideFooterBanner
  >
    <PageBackground
      title="Thank You!"
      description="A member of our team will be in touch soon. We look forward to speaking with you."
    />
    <ContactBanner />
  </Layout>
)

export default NotFoundPage
